import classNames from 'classnames';
import { CSSProperties, ReactNode } from 'react';

import styles from './styles.module.scss';

export type Size = 'small' | 'medium' | 'large';

type Tag = 'div' | 'section';

export type LayoutContainerProps = {
  children: ReactNode;
  size?: Size;
  className?: string;
  tag?: Tag;
  style?: CSSProperties;
};

export function LayoutContainer({
  children,
  className,
  size = 'small',
  tag: Tag = 'div',
  style,
  ...rest
}: LayoutContainerProps) {
  return (
    <Tag
      className={classNames(styles.container, styles[size], className)}
      style={style}
      {...rest}
    >
      {children}
    </Tag>
  );
}
