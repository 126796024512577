import classNames from 'classnames';
import Link, { LinkProps } from 'next/link';

import { Typography } from 'components/typography';

import { Icon, Icons } from '../internalComponents/icon';
import styles from './styles.module.scss';

export type LinkButtonProps = Partial<LinkProps> & {
  leadingIcon?: keyof typeof Icons;
  trailingIcon?: keyof typeof Icons;
  label: string;
  variant?: 'regular' | 'compact';
  className?: string;
};

export const LinkButton = ({
  leadingIcon,
  trailingIcon,
  label,
  variant = 'regular',
  className,
  href,
  ...rest
}: LinkButtonProps) => {
  return (
    <Link
      href={href ?? ''}
      className={classNames(styles.linkButton, styles[variant], className)}
      {...rest}
    >
      {leadingIcon && (
        <Icon className={styles.leadingIcon} name={leadingIcon} />
      )}
      <Typography
        variant={variant === 'regular' ? 'label-reg' : 'label-compact'}
        tag="span"
        className={styles.label}
      >
        {label}
      </Typography>
      {trailingIcon && (
        <Icon className={styles.trailingIcon} name={trailingIcon} />
      )}
    </Link>
  );
};
