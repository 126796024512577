import { ReactNode } from 'react';

import { AbstractButton } from '../../internalComponents/abstractButton/abstractButton';
import { Icon, IconProps } from '../../internalComponents/icon';
import styles from './styles.module.scss';

export interface ButtonProps {
  className?: string;
  style?: 'primary' | 'secondary' | 'tertiary' | 'underline';
  surface?: 'neutral' | 'inverted';
  isNav?: boolean;
  isActive?: boolean;
  children: string;
  onClick?: (id?: string) => void;
  href?: string;
  icon?: IconProps['name'];
  type?: 'submit' | 'button';
  disabled?: boolean;
}

export const Button = ({
  className,
  children,
  onClick,
  style,
  surface = 'neutral',
  isNav,
  isActive,
  href,
  icon,
  type = 'button',
  disabled,
}: ButtonProps) => {
  let content: string | ReactNode = children;

  if (style === 'underline') {
    content = (
      <span key={children} className={styles.wrapper}>
        {children}
      </span>
    );
  }

  const getButtonStyle = () => {
    if (style) {
      return style;
    }
    if (!href) {
      return 'secondary';
    }
    if (href?.includes('//')) {
      return 'tertiary';
    }
    return 'primary';
  };

  return (
    <AbstractButton
      href={href}
      onClick={onClick}
      className={`${styles.button} ${styles[getButtonStyle()]} ${
        styles[surface]
      } ${isNav ? styles.isNav : ''} ${isActive ? styles.active : ''} ${
        className ?? ''
      }`}
      type={type}
      disabled={disabled}
    >
      {content}
      {icon && <Icon name={icon} />}
    </AbstractButton>
  );
};
