import Link from 'next/link';
import { HTMLAttributes, ReactNode } from 'react';

import styles from './abstractButton.module.scss';

type Element = Omit<
  HTMLAttributes<HTMLButtonElement | HTMLAnchorElement>,
  'className' | 'children' | 'onClick' | 'href'
>;

interface AbstractButtonProps extends Element {
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
  href?: string;
  type?: 'submit' | 'button';
  disabled?: boolean;
}

export const AbstractButton = ({
  className,
  children,
  onClick,
  href,
  ...props
}: AbstractButtonProps) => {
  if (!href) {
    return (
      <button
        className={`${styles.abstractButton} ${className ?? ''}`}
        onClick={onClick}
        {...props}
      >
        {children}
      </button>
    );
  }

  return href.includes('//') ? (
    <a
      className={`${styles.abstractButton} ${className ?? ''}`}
      onClick={onClick}
      href={href}
      target="_blank"
      rel="noreferrer nofollow"
      {...props}
    >
      {children}
    </a>
  ) : (
    <Link
      href={href}
      className={`${styles.abstractButton} ${className ?? ''}`}
      onClick={onClick}
      {...props}
    >
      {children}
    </Link>
  );
};
