export function scrollTo(documentId: string, offset = 0): void {
  const element = document.getElementById(documentId);
  if (!element) {
    throw new Error(`Trying to scroll to ${documentId} but no element found.`);
  }
  const clientRect = element.getBoundingClientRect();
  const top = clientRect.top + scrollY + offset;

  window.scrollTo({
    behavior: 'smooth',
    top,
  });
}
