import classNames from 'classnames';
import { CSSProperties, ReactNode } from 'react';

import styles from './styles.module.scss';

type Tag =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'span'
  | 'blockquote'
  | 'a'
  | 'label';

type Variant =
  | 'heading1-display'
  | 'heading1-display-cases'
  | 'heading1-sub'
  | 'heading2-compact'
  | 'heading2-sub'
  | 'heading3-regular'
  | 'heading3-regular-alt'
  | 'heading3-sub'
  | 'paragraph-small'
  | 'paragraph-reg'
  | 'label-small'
  | 'label-reg'
  | 'label-compact'
  | 'link'
  | 'lead'
  | 'input'
  | 'input-label'
  | 'input-support'
  | 'blockquote'
  | 'list-item-heading'
  | 'list-item-subheading';

type AnchorProps = {
  href: string;
  target: string;
  rel: string;
};

type LabelProps = {
  htmlFor: string;
};

export type TypographyProps = Partial<AnchorProps | LabelProps> & {
  variant: Variant;
  children: ReactNode;
  tag: Tag;
  fontFamily?: 'font-neue-plak' | 'font-inter';
  className?: string;
  color?: 'primary' | 'secondary' | 'white' | 'muted' | 'error';
  style?: CSSProperties;
};

export function Typography({
  variant,
  children,
  tag: Tag,
  fontFamily,
  className,
  style,
  color = 'secondary',
  ...rest
}: TypographyProps) {
  const defaultFontFamily =
    Tag !== 'p' &&
    Tag !== 'span' &&
    Tag !== 'label' &&
    Tag !== 'a' &&
    Tag !== 'blockquote'
      ? 'font-neue-plak'
      : 'font-inter';

  return (
    <Tag
      className={classNames(
        styles.typography,
        styles[fontFamily ?? defaultFontFamily],
        styles[variant],
        styles[color],
        className
      )}
      style={style}
      {...rest}
    >
      {children}
    </Tag>
  );
}
